import { useIsScimEnabled, useManualSCIMSync } from '../../../hooks/useScim.ts'
import React from 'react'
import { LoadingButton } from '@mui/lab'
import Iconify from '../../Iconify.tsx'

export function ManualSCIMSyncButton(){
  const scimEnabled = useIsScimEnabled()
  const { mutateAsync: scimSync, isLoading: syncing } = useManualSCIMSync()
  const handleClick = () => scimSync()

  if(!scimEnabled) return <></>

  return (
    <LoadingButton
      variant='contained'
      color='mint'
      size='small'
      startIcon={<Iconify icon={'ph:arrows-clockwise'} />}
      onClick={handleClick}
      disabled={syncing}
      loading={syncing}
    >
      Sync Users
    </LoadingButton>
  )
}