import { Asset } from '../../../../@types/asset.ts'
import { AssetContentState } from '../../../../@types/asset-content.ts'
import { NoValidAccountErrorData } from '../../../../@types/asset-content-errors.ts'
import { useAccountsOfType } from '../../../../hooks/useAccounts.ts'
import { Box, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import React from 'react'
import { OAUTH_PROVIDER_NAMES } from '../../../../@types/oauth.ts'
import { fDateTime } from '../../../../utils/formatTime.ts'
import { ConnectAccountButton } from '../../oauth/ConnectAccountButton.tsx'
import { useRetryRawFileExtraction } from '../../../../hooks/useAssetContent.ts'

export function RawFileNoValidAccountError({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const data = state.errorData as NoValidAccountErrorData

  // hooks
  const { data: accounts, isLoading, isError } = useAccountsOfType(data.accountType)
  const { mutateAsync: retryExtractionWorkflow, isLoading: retryLoading } = useRetryRawFileExtraction()

  // handlers
  const handleRetryDownloadClick = () => retryExtractionWorkflow({ assetId: asset.assetId })

  // calculated props
  const providerName = OAUTH_PROVIDER_NAMES[data.accountType] || ''
  const hasAccounts = !isLoading && !isError && accounts.length > 0
  const hasNoAccounts = !isLoading && !isError && accounts.length == 0

  return (
    <Stack spacing={2}>
      <Typography variant='standardHighlight' color="warning.main" paragraph>
        Missing {providerName} Account
      </Typography>

      <Typography variant='standard'>We last attempted to download this
        asset <b>{fDateTime(state.updatedAt)}</b></Typography>

      <Typography variant='standard' paragraph>
        At that time, you did not have any {providerName} accounts connected. Unfortunately due to restrictions placed
        by {providerName}, Masset cannot download these assets without a connected account.
      </Typography>

      {
        hasNoAccounts && (
          <>
            <Typography variant='standard' paragraph>
              If you have access to the relevant {providerName} account, you can connect it below.
            </Typography>

            <Box>
              <ConnectAccountButton provider={data.accountType} />
            </Box>
          </>
        )
      }

      {hasAccounts && (
        <>
          <Typography variant='standard' paragraph>
            Since that time, new accounts have been connected. With these new accounts, we can try the download again!
          </Typography>

          <Stack pt={1} direction='row' spacing={1} alignItems='center'>
            <Typography variant='standard'>Got it fixed? </Typography>
            <LoadingButton
              variant='text'
              size='small'
              onClick={handleRetryDownloadClick}
              color='primary'
              loading={retryLoading}
              loadingPosition='start'
            >
              Redownload
            </LoadingButton>
          </Stack>
        </>
      )}


    </Stack>
  )
}