import api from '@api'
import {
  UpdateAssetOCRContentsResponse,
  GetAssetDocumentSummaryResponse,
  GetAssetGeneratedQuestionsResponse,
  GetAssetImageSummaryResponse,
  GetAssetOCRContentsResponse,
  GetAssetTranscriptionResponse,
  RetryAssetImageSummaryResponse,
  RetryAssetOCRContentsResponse,
  UpdateAssetImageSummaryResponse,
  RetryAssetDocumentSummaryResponse,
  UpdateAssetDocumentSummaryResponse,
} from '../@types/asset-content'

export async function getAssetTranscription(assetId: string) {
  const response = await api.get<GetAssetTranscriptionResponse>(`/api/v1/assets/${assetId}/content/transcription`)
  return response.data
}

export async function getAssetDocumentSummary(assetId: string) {
  const response = await api.get<GetAssetDocumentSummaryResponse>(`/api/v1/assets/${assetId}/content/document-summary`)
  return response.data
}

export async function retryDocumentSummaryForAsset(assetId: string) {
  const response = await api.post<RetryAssetDocumentSummaryResponse>(`/api/v1/assets/${assetId}/content/document-summary/retry`)
  return response.data
}

export async function updateDocumentSummaryForAsset(assetId: string, assetContentId: string, contents: string) {
  const response = await api.put<UpdateAssetDocumentSummaryResponse>(
    `/api/v1/assets/${assetId}/content/document-summary/${assetContentId}/contents`,
    { summary: contents },
  )
  return response.data
}

export async function getAssetGeneratedQuestions(assetId: string) {
  const response = await api.get<GetAssetGeneratedQuestionsResponse>(`/api/v1/assets/${assetId}/content/questions`)
  return response.data
}

export async function getAssetImageSummary(assetId: string) {
  const response = await api.get<GetAssetImageSummaryResponse>(`/api/v1/assets/${assetId}/content/image-summary`)
  return response.data
}

export async function retryImageSummaryForAsset(assetId: string) {
  const response = await api.post<RetryAssetImageSummaryResponse>(`/api/v1/assets/${assetId}/content/image-summary/retry`)
  return response.data
}

export async function updateImageSummaryForAsset(assetId: string, assetContentId: string, contents: string) {
  const response = await api.put<UpdateAssetImageSummaryResponse>(
    `/api/v1/assets/${assetId}/content/image-summary/${assetContentId}/contents`,
    { contents: contents },
  )
  return response.data
}

export async function getAssetOCRContents(assetId: string) {
  const response = await api.get<GetAssetOCRContentsResponse>(`/api/v1/assets/${assetId}/content/ocr-contents`)
  return response.data
}

export async function retryOCRForAsset(assetId: string) {
  const response = await api.post<RetryAssetOCRContentsResponse>(`/api/v1/assets/${assetId}/content/ocr-contents/retry`)
  return response.data
}

export async function updateOCRForAsset(assetId: string, assetContentId: string, contents: string) {
  const response = await api.put<UpdateAssetOCRContentsResponse>(
    `/api/v1/assets/${assetId}/content/ocr-contents/${assetContentId}/contents`,
    { contents: contents },
  )
  return response.data
}

export async function retryRawFileExtraction(assetId: string) {
  const response = await api.post(`/api/v1/assets/${assetId}/content/raw-file/retry`)
  return response.data
}
