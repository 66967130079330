import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  updateImageSummaryForAsset,
  updateOCRForAsset,
  getAssetDocumentSummary,
  getAssetGeneratedQuestions,
  getAssetImageSummary,
  getAssetOCRContents,
  getAssetTranscription, retryImageSummaryForAsset,
  retryOCRForAsset, retryDocumentSummaryForAsset, updateDocumentSummaryForAsset, retryRawFileExtraction,
} from '../clients/AssetContentClient'
import { AssetDocumentSummary, AssetImageSummary, AssetOCRContents } from '../@types/asset-content.ts'

export function useAssetTranscription(assetId: string, enabled: boolean = true) {
  return useQuery({
    enabled: enabled,
    queryKey: ['asset-content', assetId, 'transcription'],
    queryFn: () => getAssetTranscription(assetId),
  })
}

export function useAssetDocumentSummary(assetId: string, enabled: boolean = true) {
  return useQuery({
    enabled: enabled,
    queryKey: ['asset-content', assetId, 'document-summary'],
    queryFn: () => getAssetDocumentSummary(assetId),
  })
}

export function useAssetGeneratedQuestions(assetId: string, enabled: boolean = true) {
  return useQuery({
    enabled: enabled,
    queryKey: ['asset-content', assetId, 'generated-questions'],
    queryFn: () => getAssetGeneratedQuestions(assetId),
  })
}

export function useAssetImageSummary(assetId: string, enabled: boolean = true) {
  return useQuery({
    enabled: enabled,
    queryKey: ['asset-content', assetId, 'image-summary'],
    queryFn: () => getAssetImageSummary(assetId),
  })
}

export function useAssetOCRContents(assetId: string, enabled: boolean = true) {
  return useQuery({
    enabled: enabled,
    queryKey: ['asset-content', assetId, 'ocr-contents'],
    queryFn: () => getAssetOCRContents(assetId),
  })
}

type RetryOCRContentsParams = {
  assetId: string
}
export function useRetryOCRContents() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: RetryOCRContentsParams) => retryOCRForAsset(params.assetId),
    onSuccess: (data, { assetId }) => {
      queryClient.setQueryData(
        ['asset-content', assetId, 'ocr-contents'],
        (oldData?: AssetOCRContents) => oldData ? { ...oldData, ...data } : oldData,
      )
      queryClient.invalidateQueries(['asset-content-state', assetId])
    }
  })
}

type EditOCRContentsParams = {
  assetId: string
  assetContentId: string,
  contents: string
}
export function useEditOCRContents() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: EditOCRContentsParams) => updateOCRForAsset(params.assetId, params.assetContentId, params.contents),
    onSuccess: (data, { assetId }) => {
      queryClient.setQueryData(
        ['asset-content', assetId, 'ocr-contents'],
        (oldData?: AssetOCRContents) => oldData ? { ...oldData, ...data } : oldData,
      )
    }
  })
}


type RetryImageSummaryParams = {
  assetId: string
}
export function useRetryImageSummary() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: RetryImageSummaryParams) => retryImageSummaryForAsset(params.assetId),
    onSuccess: (data, { assetId }) => {
      queryClient.setQueryData(
        ['asset-content', assetId, 'image-summary'],
        (oldData?: AssetOCRContents) => oldData ? { ...oldData, ...data } : oldData,
      )
      queryClient.invalidateQueries(['asset-content-state', assetId])
    }
  })
}


type EditImageSummaryContentsParams = {
  assetId: string
  assetContentId: string,
  contents: string
}
export function useUpdateImageSummaryContents() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: EditImageSummaryContentsParams) => updateImageSummaryForAsset(params.assetId, params.assetContentId, params.contents),
    onSuccess: (data, { assetId }) => {
      queryClient.setQueryData(
        ['asset-content', assetId, 'image-summary'],
        (oldData?: AssetImageSummary) => oldData ? { ...oldData, ...data } : oldData,
      )
      queryClient.invalidateQueries(['asset-content-state', assetId])
    }
  })
}

type RetryDocumentSummaryParams = {
  assetId: string
}
export function useRetryDocumentSummary() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: RetryDocumentSummaryParams) => retryDocumentSummaryForAsset(params.assetId),
    onSuccess: (data, { assetId }) => {
      queryClient.setQueryData(
        ['asset-content', assetId, 'document-summary'],
        (oldData?: AssetDocumentSummary) => oldData ? { ...oldData, ...data } : oldData,
      )
      queryClient.invalidateQueries(['asset-content-state', assetId])
    }
  })
}


type UpdateDocumentSummaryParams = {
  assetId: string,
  assetContentId: string,
  contents: string
}
export function useUpdateDocumentSummary() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: UpdateDocumentSummaryParams) => updateDocumentSummaryForAsset(params.assetId, params.assetContentId, params.contents),
    onSuccess: (data, { assetId }) => {
      queryClient.setQueryData(
        ['asset-content', assetId, 'document-summary'],
        (oldData?: AssetDocumentSummary) => oldData ? { ...oldData, ...data } : oldData,
      )
      queryClient.invalidateQueries(['asset-content-state', assetId])
    }
  })
}

type RetryRawFileAccessParams = {
  assetId: string
}
export function useRetryRawFileExtraction() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: RetryRawFileAccessParams) => retryRawFileExtraction(params.assetId),
    onSuccess: (_data, { assetId }) => {
      queryClient.invalidateQueries(['asset-content-state', assetId])
    }
  })
}
