import { Asset } from '../../../../@types/asset.ts'
import { AssetContentState } from '../../../../@types/asset-content.ts'
import { Link, Stack, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import React from 'react'
import { useRetryRawFileExtraction } from '../../../../hooks/useAssetContent.ts'
import { LoadingButton } from '@mui/lab'

export function RawFileUnauthenticatedError({ asset, state }: { asset: Asset, state: AssetContentState }) {
  // const data = state.errorData as UnauthenticatedErrorData
  const { mutateAsync: retryExtractionWorkflow, isLoading } = useRetryRawFileExtraction()
  const handleRetryDownloadClick = () => retryExtractionWorkflow({ assetId: asset.assetId })

  return (
    <Stack spacing={2}>
      <Typography variant='standardHighlight' paragraph>
        Unauthenticated attempt access to <Link href={asset.storageExternalRef}
                                                target='_blank'>{asset.storageExternalRef || ''}</Link>
      </Typography>

      <Typography variant='standard' paragraph>
        The link you provided appears to be private, which means Masset can't access it without being logged in. Unless
        granted access through integrations, Masset cannot access private links.
      </Typography>

      <Typography variant='standard' paragraph>
        If you have access to download the asset at this link, go the &nbsp;
        <Link component={RouterLink} to='/profile/integrations' target='_blank'>Integrations</Link>
        &nbsp; page to ensure you have it connected!
      </Typography>

      <Stack pt={1} direction="row" spacing={1} alignItems="center">
        <Typography variant='standard'>Got it fixed? </Typography>
        <LoadingButton
          variant='text'
          size="small"
          onClick={handleRetryDownloadClick}
          color='primary'
          loading={isLoading}
          loadingPosition='start'
        >
          Redownload
        </LoadingButton>
      </Stack>
    </Stack>
  )
}