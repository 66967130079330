import React, { useContext } from 'react'
import Pagination from '../pagination/Pagination.tsx'
import { ContentAnalysisFilterContext } from '../../contexts/ContentAnalysisFilterContext.tsx'
import { useContentAudit } from '../../hooks/useContentAudit.ts'

export default function ContentAnalysisPagination() {
  const { currentPage, pageSize, setPage } = useContext(ContentAnalysisFilterContext)
  const { data: auditData } = useContentAudit()
  const totalResultCount = auditData?.totalResultCount || 0
  const handlePageChange = (pageNum: number) => setPage(pageNum)
  return (
    <Pagination
      currentPage={currentPage}
      pageSize={pageSize}
      totalResultCount={totalResultCount}
      onPageChange={handlePageChange}
      scrollOnPageChange={false}
    />
  )
}