import { Asset } from '../../../../@types/asset.ts'
import { AssetContentState } from '../../../../@types/asset-content.ts'
import { NotFoundErrorData } from '../../../../@types/asset-content-errors.ts'
import { Link, Stack, Typography } from '@mui/material'
import { useAccount } from '../../../../hooks/useAccounts.ts'
import { useRetryRawFileExtraction } from '../../../../hooks/useAssetContent.ts'
import React from 'react'
import { LoadingButton } from '@mui/lab'
import { AccessResolutionByAccountType } from '../access/AccessResolutionByAccountType.tsx'
import { fDateTime } from '../../../../utils/formatTime.ts'

export function RawFileNotFoundError({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const data = state.errorData as NotFoundErrorData
  const { data: account } = useAccount(data?.oauthAccountId)
  const { mutateAsync: retryExtractionWorkflow, isLoading: retryLoading } = useRetryRawFileExtraction()
  const handleRetryDownloadClick = () => retryExtractionWorkflow({ assetId: asset.assetId })
  return (
    <Stack spacing={2}>
      <Typography variant='standardHighlight' color='warning.main' paragraph>
        Remote Asset Not Found (or not permitted)
      </Typography>

      <Typography variant='standard'>We couldn't find an asset located at &nbsp;<Link
        href={asset.storageExternalRef} target="_blank">{asset.storageExternalRef}</Link> </Typography>

      <Typography variant='standard'>This may happen if: </Typography>
      <Typography variant='standard' sx={{ pl: 4 }}>
        <ol>
          <li>The link exists, but the connected account doesn't have access to it</li>
          <li>The link has been deleted</li>
          <li>The link has been entered incorrectly</li>
        </ol>
      </Typography>

      <Typography variant='standard'>We last attempted to download this
        asset <b>{fDateTime(state.updatedAt)}</b></Typography>

      <AccessResolutionByAccountType account={account} asset={asset} />

      <Stack pt={1} direction='row' spacing={1} alignItems='center'>
        <Typography variant='standard'>Got it fixed?</Typography>
        <LoadingButton
          variant='text'
          size='small'
          onClick={handleRetryDownloadClick}
          color='primary'
          loading={retryLoading}
          loadingPosition='start'
        >
          Redownload
        </LoadingButton>
      </Stack>
    </Stack>
)
}