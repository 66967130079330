import React, { useContext } from 'react'
import { Box, Paper } from '@mui/material'
import { fShortenNumber } from '../../../utils/formatNumber.ts'
import Iconify from '../../Iconify.tsx'
import { useTheme } from '@mui/material/styles'
import { useContentAuditSummary } from '../../../hooks/useContentAudit.ts'
import { ContentAnalysisFilterContext } from '../../../contexts/ContentAnalysisFilterContext.tsx'

export default function NotInConversationsWidget() {
  const theme = useTheme()
  const {  setAvailableInConversations } = useContext(ContentAnalysisFilterContext)
  const { data: summary, isLoading, isError } = useContentAuditSummary()

  const handleClick = () => setAvailableInConversations(false, true)

  if(isLoading || isError){
    return <></>
  }

  return (
    <Paper
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
        pl: 3,
      }}
      onClick={handleClick}
    >
      <Box>
        <Box sx={{ mb: 1, typography: "h2" }}>{fShortenNumber(summary.countMissingFromConversations)}</Box>
        <Box sx={{ color: "text.secondary", typography: "smallHighlight" }}>Missing From Conversations</Box>
      </Box>

      <Box
        sx={{
          width: 64,
          height: 64,
          lineHeight: 0,
          borderRadius: "50%",
          bgcolor: "background.neutral"
        }}
      >
        <Iconify icon="ph:chat-circle-slash-duotone" color={theme.palette.warning.main} width={64} height={64}/>
      </Box>
    </Paper>
  )
}