import {
  Alert,
  Box,
  Button,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField, Tooltip,
  Typography,
} from '@mui/material'
import { USER_TYPE_DISPLAY_VALUE, UserSortBy, UserStatus } from '../../@types/user'
import Iconify from '../../components/Iconify'
import { UserDeleteButton } from '../../components/admin/user/UserDeleteButton'
import { useNavigate } from 'react-router-dom'
import Page from '../../components/Page'
import { DEFAULT_USER_SEARCH_DATA, useAdminUserSearch } from '../../hooks/useUsers'
import React, { ChangeEvent, useState } from 'react'
import Pagination from '../../components/pagination/Pagination'
import { visuallyHidden } from '@mui/utils'
import { useDebounceValue } from 'usehooks-ts'
import { ManualSCIMSyncButton } from '../../components/admin/user/ManualSCIMSyncButton.tsx'
import { UserListScimAlertBanner } from '../../components/admin/user/UserListScimAlertBanner.tsx'
import { useIsScimEnabled } from '../../hooks/useScim.ts'

const ADMIN_USERS_PAGE_SIZE = 50
const DEFAULT_SORT = {
  sortBy: UserSortBy.NAME,
  direction: 'asc',
} as Sort

export default function AdminUsers() {
  const [page, setPage] = useState<number>(1)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [sort, setSort] = useState<Sort>(DEFAULT_SORT)
  const navigate = useNavigate()
  const [debouncedSearchTerm] = useDebounceValue(searchTerm, 200)
  const scimEnabled = useIsScimEnabled()
  const { data } = useAdminUserSearch({
    term: debouncedSearchTerm,
    sortBy: sort.sortBy,
    sortDirection: sort.direction,
    limit: ADMIN_USERS_PAGE_SIZE,
    page,
  })
  const results = (data || DEFAULT_USER_SEARCH_DATA)
  const users = results.users
  const totalResultCount = results.totalResultCount

  //handlers
  const handleSearchTermChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setPage(1)
    setSearchTerm(ev.target.value)
  }
  const handleSearchTermClear = () => {
    setPage(1)
    setSearchTerm('')
  }
  const handlePageChange = (pageNum: number) => setPage(pageNum + 1)
  const handleSortChange = (sortBy: UserSortBy, direction: 'asc' | 'desc') => {
    setPage(1)
    setSort({ sortBy, direction })
  }

  const createAllowed = !scimEnabled
  const disabledButtonTooltip = scimEnabled ? 'Blocked because external user syncing is enabled.' : null

  return (
    <Page title='Users'>
      <Stack direction='column' spacing={2}>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='h2'>User Management</Typography>
          <Stack direction="row" spacing={1}>
            <Tooltip title={disabledButtonTooltip}>
              <Box>
                <Button
                  variant='contained'
                  color='mint'
                  size='small'
                  startIcon={<Iconify icon={'eva:plus-outline'} />}
                  onClick={() => navigate('/admin/users/new')}
                  disabled={!createAllowed}
                >
                  Add New User
                </Button>
              </Box>
            </Tooltip>
            <ManualSCIMSyncButton />
          </Stack>

        </Stack>
        <UserListScimAlertBanner />
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <TextField
            size='small'
            onChange={handleSearchTermChange}
            placeholder='Search...'
            value={searchTerm}
            sx={{ width: 360 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Iconify icon='eva:search-outline' />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position='end'>
                  <Iconify
                    icon='eva:close-fill'
                    onClick={handleSearchTermClear}
                    sx={{ cursor: 'pointer' }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Pagination
            currentPage={page - 1}
            pageSize={ADMIN_USERS_PAGE_SIZE}
            totalResultCount={totalResultCount}
            onPageChange={handlePageChange}
            scrollOnPageChange={false}
          />
        </Stack>
        <Table>
          <TableHead>
            <TableRow>
              <SortableTableHeader
                label='Name'
                col={UserSortBy.NAME}
                curSort={sort}
                onSortChange={handleSortChange}
              />
              <SortableTableHeader
                label='Email'
                col={UserSortBy.EMAIL}
                curSort={sort}
                onSortChange={handleSortChange}
              />
              <SortableTableHeader
                label='Role'
                col={UserSortBy.ROLE}
                curSort={sort}
                onSortChange={handleSortChange}
              />
              <SortableTableHeader
                label='Status'
                col={UserSortBy.STATUS}
                curSort={sort}
                onSortChange={handleSortChange}
              />
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              users.map(user => {
                return (
                  <TableRow key={user.userId}>
                    <TableCell><Typography
                      variant='smallHighlight'>{user.firstName} {user.lastName}</Typography></TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{USER_TYPE_DISPLAY_VALUE[user.userType]}</TableCell>
                    <TableCell><UserStatusCmp status={user.status} /></TableCell>
                    <TableCell>
                      <Stack
                        spacing={1}
                        direction='row'
                        justifyContent='end'
                      >
                        <Button
                          size='small'
                          variant='text'
                          color='mint'
                          startIcon={
                            <Iconify color='text.mint' icon='eva:edit-outline' />
                          }
                          sx={{
                            '& .MuiButton-startIcon': { marginRight: '4px' },
                          }}
                          onClick={() => navigate(`/admin/users/${user.userId}`)}
                        >Edit</Button>
                        <UserDeleteButton user={user} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
        <Pagination
          currentPage={page - 1}
          pageSize={ADMIN_USERS_PAGE_SIZE}
          totalResultCount={totalResultCount}
          onPageChange={handlePageChange}
          scrollOnPageChange={false}
        />
      </Stack>
    </Page>
  )
}


function UserStatusCmp({ status }: { status: UserStatus }) {
  if (status === UserStatus.INVITED) {
    return (
      <Stack direction='row' spacing={1}>
        <Typography variant='small' color='text.deemphasized'>(Pending Invite)</Typography>
      </Stack>
    )
  }

  if (status === UserStatus.ACTIVE) {
    return (
      <Typography variant='small' color='text.deemphasized'>Active</Typography>
    )
  }

  return <></>
}


interface Sort {
  sortBy: UserSortBy
  direction: 'asc' | 'desc'
}

interface SortableTableCellProps {
  label: string,
  col: UserSortBy,
  curSort: Sort,
  onSortChange: (sortBy: UserSortBy, direction: 'asc' | 'desc') => void
}

function SortableTableHeader({ label, col, curSort, onSortChange }: SortableTableCellProps) {
  const isActive = curSort.sortBy === col

  const handleSortChange = () => {
    const opposite = curSort.direction === 'asc' ? 'desc' : 'asc'
    const newDirection = curSort.sortBy === col ? opposite : 'asc'
    onSortChange(col, newDirection)
  }

  return (
    <TableCell>
      <TableSortLabel
        active={isActive}
        direction={isActive ? curSort.direction : 'asc'}
        onClick={handleSortChange}
      >
        {isActive ? (
          <Box component='span' sx={visuallyHidden}>
            {curSort.direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
        <Typography variant='small'>{label}</Typography>
      </TableSortLabel>
    </TableCell>
  )
}