import { ContentAnalysisSortBy } from '../../@types/content-audit.ts'
import React, { useContext } from 'react'
import { ContentAnalysisFilterContext } from '../../contexts/ContentAnalysisFilterContext.tsx'
import { Box, TableCell, TableSortLabel, Typography } from '@mui/material'
import { visuallyHidden } from '@mui/utils'

export function SortableHeaderTableCell({ label, col }: { label: string, col: ContentAnalysisSortBy }) {
  const { sortBy, sortDirection, setSort } = useContext(ContentAnalysisFilterContext)
  const isActive = sortBy === col
  const handleSortChange = () => {
    const newDirection = !isActive || sortDirection == "desc" ? "asc" : "desc"
    setSort(col, newDirection)
  }
  return (
    <TableCell>
      <TableSortLabel
        active={isActive}
        direction={isActive ? sortDirection : 'desc'}
        onClick={() => handleSortChange()}
      >
        {isActive ? (
          <Box component="span" sx={visuallyHidden}>
            {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
        <Typography variant='small'>{label}</Typography>

      </TableSortLabel>
    </TableCell>
  )
}
