import React, { useContext } from 'react'
import { Box, Paper } from '@mui/material'
import { fShortenNumber } from '../../../utils/formatNumber.ts'
import Iconify from '../../Iconify.tsx'
import { useTheme } from '@mui/material/styles'
import { useContentAuditSummary } from '../../../hooks/useContentAudit.ts'
import { ContentAnalysisFilterContext } from '../../../contexts/ContentAnalysisFilterContext.tsx'

export default function LackingFileAccessWidget() {
  const theme = useTheme()
  const { setHasFileAccess } = useContext(ContentAnalysisFilterContext)
  const { data: summary, isLoading, isError } = useContentAuditSummary()

  const handleClick = () => setHasFileAccess(false, true)

  if (isLoading || isError) {
    return <></>
  }
  return (
    <Paper
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        pl: 3,
      }}
      onClick={handleClick}
    >
      <Box>
        <Box sx={{ mb: 1, typography: 'h2' }}>{fShortenNumber(summary.countLackingFileAccess)}</Box>
        <Box sx={{ color: 'text.secondary', typography: 'smallHighlight' }}>Lacking File Access</Box>
      </Box>

      <Box
        sx={{
          width: 64,
          height: 64,
          lineHeight: 0,
          borderRadius: '50%',
          bgcolor: 'background.neutral',
        }}
      >
        <Iconify icon='ph:file-x-duotone' color={theme.palette.info.main} width={64} height={64} />
      </Box>
    </Paper>
  )
}