import { useContentAudit } from '../../hooks/useContentAudit.ts'
import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import ContentAnalysisPagination from './ContentAnalysisPagination.tsx'
import { ContentAnalysisSortBy } from '../../@types/content-audit.ts'
import React from 'react'
import { SortableHeaderTableCell } from './SortableHeaderTableCell.tsx'
import { ContentAnalysisTableRow } from './ContentAnalysisTableRow.tsx'
import { ContentAnalysisFilters } from './ContentAnalysisFilters.tsx'
import Iconify from '../Iconify.tsx'

export const ContentAnalysisTable = () => {
  const { data: auditData, isLoading, isError, error } = useContentAudit()

  return (
    <Box>
      <Stack
        direction='row'
        justifyContent='space-between'
        py={1}
      >
        <ContentAnalysisFilters />
        <ContentAnalysisPagination />
      </Stack>
      <Table stickyHeader size='small'>
        <TableHead>
          <TableRow>
            <TableCell />
            <SortableHeaderTableCell label='Name' col={ContentAnalysisSortBy.NAME} />
            <TableCell>
              <Stack direction="row" spacing={.5} alignItems="center">
                File Access
                <Tooltip title="Does Masset have access to the asset file in order to process it?">
                  <Box>
                    <Iconify icon='ph:question' height={16} width={16} sx={{ ml: .5 }} />
                  </Box>
                </Tooltip>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack direction="row" spacing={.5} alignItems="center">
                In Conversations
                <Tooltip title={
                  <Stack spacing={1}>
                    <Typography variant='small'>Assets can be included in conversations even if some extraction processes
                      fail. For example, an image with a summary can still be included, even if the OCR process
                      fails.</Typography>
                    <Typography variant='small'>To be included in conversations, an asset must have <i><b>at least one</b></i> extraction
                      process
                      succeed.</Typography>
                    <Typography variant='small'> If no processes have succeeded, it will not be included, not even
                      partially.</Typography>
                  </Stack>
                }>
                  <Box>
                    <Iconify icon='ph:question' height={16} width={16} sx={{ ml: .5 }} />
                  </Box>
                </Tooltip>
              </Stack>

            </TableCell>
            <SortableHeaderTableCell label='Grade' col={ContentAnalysisSortBy.GRADE_LEVEL} />
          </TableRow>
        </TableHead>
        <TableBody>
          {auditData?.results.map((row, idx) => (
            <ContentAnalysisTableRow key={row.assetId} row={row} rowNum={idx} />
          ))}
        </TableBody>
      </Table>

      <Box py={1}>
        <ContentAnalysisPagination />
      </Box>
    </Box>
  )
}

