import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import Page from '../../components/Page.tsx'
import { ContentAnalysisProvider } from '../../contexts/ContentAnalysisFilterContext.tsx'
import { ContentAnalysisTable } from '../../components/content-analysis/ContentAnalysisTable.tsx'
import NeedsAttentionWidget from '../../components/content-analysis/widgets/NeedsAttentionWidget.tsx'
import NotInConversationsWidget from '../../components/content-analysis/widgets/NotInConversationsWidget.tsx'
import LackingFileAccessWidget from '../../components/content-analysis/widgets/LackingFileAccessWidget.tsx'

export const ContentAnalysis = () => {
  return (
    <Page title='Content Analysis' style={{ padding: '24px' }}>
      <Typography variant='h2'> Content Analysis </Typography>
      <Typography variant='standard' paragraph mt={2} mb={4}>
        Audit your assets to ensure that they are ready to be used by MassetBot in Content Conversations.
      </Typography>

      <ContentAnalysisProvider>

        <Grid container spacing={4} mb={4}>
          <Grid item xs={4}>
            <NeedsAttentionWidget />
          </Grid>
          <Grid item xs={4}>
            <NotInConversationsWidget />
          </Grid>
          <Grid item xs={4}>
            <LackingFileAccessWidget />
          </Grid>
        </Grid>

        <ContentAnalysisTable />

      </ContentAnalysisProvider>

    </Page>
  )
}

