import React from 'react'
import { Stack } from '@mui/material'
import { HasFileAccessFilterDropdown } from './filters/HasFileAccessFilterDropdown.tsx'
import { InConversationsFilterDropdown } from './filters/InConversationsFilterDropdown.tsx'
import { ContentGradeFilterDropdown } from './filters/ContentGradeFilterDropdown.tsx'

export function ContentAnalysisFilters() {
  return (
    <Stack direction='row' spacing={2}>
      <ContentGradeFilterDropdown />
      <HasFileAccessFilterDropdown />
      <InConversationsFilterDropdown />
    </Stack>
  )

}