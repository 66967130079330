import * as Yup from 'yup'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { Alert, Box, Button, Divider, IconButton, InputAdornment, Link, Stack, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// hooks
import useIsMountedRef from '../../hooks/useIsMountedRef'
// components
import Iconify from '../../components/Iconify'
import { FormProvider, RHFCheckbox, RHFTextField } from '../../components/hook-form'
import { useGetSAMLRedirectUrl, useLogin, useLoginSettings } from '../../hooks/useBootstrap'
import { AnimatePresence, motion } from 'framer-motion'
import { useLocalStorage } from 'usehooks-ts'

// ----------------------------------------------------------------------

const TRANSITION_DURATION = .2

type FormValuesProps = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {
  const { mutateAsync: login } = useLogin()
  const isMountedRef = useIsMountedRef()
  const [showPassword, setShowPassword] = useState(false)
  const [lastEmail, setLastEmail] = useLocalStorage("login-last-email", "")

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  })

  const defaultValues = {
    email: lastEmail,
    password: '',
    remember: true,
  }

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    resetField,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = methods

  const email = watch('email')
  const remember = watch('remember')
  const { data: loginSettings } = useLoginSettings(email)
  const { mutateAsync: getSamlRedirectUrl, isLoading: samlIsLoading } = useGetSAMLRedirectUrl()

  const handleSAMLLogin = () => {
    setLastEmail(remember ? email : "")
    getSamlRedirectUrl({ email }).then((samlInfo) => {
      window.location.assign(samlInfo?.samlRedirectUrl || '')
    })
  }

  const onSubmit = async (data: FormValuesProps) => {
    setLastEmail(remember ? email : "")
    const response = await login({ username: data.email, password: data.password })
    if (!response.success) {
      resetField('password')
      if (isMountedRef.current) {
        setError('afterSubmit', { message: response.message || '' })
      }
    }
  }

  const userNamePasswordEnabled = loginSettings?.useUsernamePassword === true
  const samlEnabled = loginSettings?.useSAML === true
  const socialEnabled = loginSettings?.useGoogleSocial === true

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit.message}</Alert>}

        <RHFTextField name='email' label='Email address' />

        <AnimatePresence>
          {
            userNamePasswordEnabled && (
              <motion.div
                key='password-field'
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: TRANSITION_DURATION }}
              >
                <RHFTextField
                  name='password'
                  label='Password'
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </motion.div>

            )
          }

        </AnimatePresence>
      </Stack>

      <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ my: 2 }}>
        <RHFCheckbox
          name='remember'
          label={<Typography variant='small'>Remember email</Typography>}
        />

        <AnimatePresence>
          {
            userNamePasswordEnabled && (
              <motion.div
                key='forgot-password-link'
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: TRANSITION_DURATION }}
              >
                <Link component={RouterLink} variant='subtitle2' to={'/password-reset'}>
                  Forgot password?
                </Link>
              </motion.div>
            )
          }
        </AnimatePresence>

      </Stack>

      <AnimatePresence>
        {userNamePasswordEnabled && (
          <motion.div
            key='login-button'
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: TRANSITION_DURATION }}
          >
            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              loading={isSubmitting}
            >
              Login
            </LoadingButton>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {samlEnabled && (
          <motion.div
            key='sso-button'
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: TRANSITION_DURATION }}
          >
            <LoadingButton
              fullWidth
              size='large'
              type='submit'
              variant='contained'
              sx={{ mt: 1 }}
              onClick={handleSAMLLogin}
              loading={samlIsLoading}
            >
              Login using SSO
            </LoadingButton>
          </motion.div>
        )}
      </AnimatePresence>


      <AnimatePresence>
        {
          socialEnabled && (
            <motion.div
              key='social-button'
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: TRANSITION_DURATION }}
            >
              <Divider sx={{ my: 3 }}>or</Divider>

              <Box>
                <Button
                  component={Link}
                  href='/api/v1/login/oauth2/authorize/google'
                  fullWidth
                  variant='outlined'
                >
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <Box
                      sx={{ width: 16 }}
                      component='img'
                      src='/assets/icons/logos/google_sso.svg'
                    />
                    <Box>Sign in with Google</Box>
                  </Stack>
                </Button>
              </Box>
            </motion.div>
          )
        }

      </AnimatePresence>

    </FormProvider>
  )
}
