import { Tabs } from '@mui/material'
import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import NavVerticalTab from '../../components/nav-section/custom/NavVerticalTab'
import Iconify from '../../components/Iconify'
import { SideBar } from '../../components/nav-section/custom/SideBar'
import { useIsFeatureEnabled } from '../../hooks/useFeatures.ts'

const ROUTE_MAPPING = {
  '/admin/users': 'users',
  '/admin/groups': 'groups',
  '/admin/invitations': 'invitations',
  '/admin/billing': 'billing',
  '/admin/company': 'company',
  '/admin/categories': 'categories',
  '/admin/content-audit': 'content-audit',
} as Record<string, string>

export default function AdminNavigation() {
  const location = useLocation()
  const contentEnabled = useIsFeatureEnabled("content-extraction")
  const tabFromPath = ROUTE_MAPPING[location.pathname] || 'users'

  return (
    <SideBar>
      <Tabs
        orientation='vertical'
        value={tabFromPath}
        TabIndicatorProps={{ sx: { display: 'none' } }}
      >
        <NavVerticalTab
          icon={<Iconify icon="ph:buildings"/>}
          label="Company Settings"
          value="company"
          component={Link}
          to="/admin/company"
        />
        <NavVerticalTab
          icon={<Iconify icon='eva:person-outline' />}
          label='Users'
          value='users'
          component={Link}
          to='/admin/users'
        />
        <NavVerticalTab
          icon={<Iconify icon='eva:people-outline' />}
          label='Groups'
          value='groups'
          component={Link}
          to='/admin/groups'
        />
        <NavVerticalTab
          icon={<Iconify icon='eva:email-outline' />}
          label='Invitations'
          value='invitations'
          component={Link}
          to='/admin/invitations'
        />
        <NavVerticalTab
          icon={<Iconify icon='eva:pricetags-outline' />}
          label='Categories'
          value='categories'
          component={Link}
          to='/admin/categories'
        />
        {
          contentEnabled && (
            <NavVerticalTab
              icon={<Iconify icon="mdi:clipboard-check-outline"/>}
              label="Content Analysis"
              value="content-audit"
              component={Link}
              to="/admin/content-audit"
            />
          )
        }

        {/*<NavVerticalTab*/}
        {/*  icon={<Iconify icon="eva:eye-outline"/>}*/}
        {/*  label="Billing and plans"*/}
        {/*  value="billing"*/}
        {/*  component={Link}*/}
        {/*  to="/admin/billing"*/}
        {/*/>*/}
      </Tabs>
    </SideBar>
  )
}