import { Asset } from '../../../@types/asset'
import { AssetContentState, AssetContentStateStatus } from '../../../@types/asset-content'
import React from 'react'
import { ReadinessContainer } from '../ReadinessContainer'
import { RawFileErrorPivot } from './RawFileErrorPivot.tsx'
import { Divider } from '@mui/material'
import { RawFileMissingPivot } from './RawFileMissingPivot.tsx'

export function RawFileReadiness({ asset, state }: { asset: Asset, state: AssetContentState }) {
  const isError = state.status == AssetContentStateStatus.FAILED
  const isMissing = state.status == AssetContentStateStatus.MISSING
  return (
    <ReadinessContainer
      icon="eva:file-outline"
      state={state}
      title="File Access"
      subtitle="Does Masset have access to the original file in order to run analysis? Without access, its hard to do other analysis!"
    >
      {isError && (
        <>
          <Divider sx={{mb:2}}/>
          <RawFileErrorPivot asset={asset} state={state} />
        </>
      )}

      {isMissing && (
        <>
          <Divider sx={{mb:2}}/>
          <RawFileMissingPivot asset={asset} state={state} />
        </>
      )}
    </ReadinessContainer>
  )
}